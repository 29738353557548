import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from "rxjs";
import {config, environment, routingEndpoint } from 'src/environments/root/environment';
import { SiteService } from '../services/site/site.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable({providedIn: 'root'})
export class Go1CountryGuard implements CanActivate {

  constructor(private router: Router, private _siteService: SiteService, private route: ActivatedRoute, private translate: TranslateService) {
  }
  canActivate(  next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const go1Enabled = environment[config.theme + this._siteService.getSiteLocale()]['config']['isGo1Enabled'];
    let registerURL:string = '';
    this.translate.getTranslation(this._siteService.getSiteLocale()).subscribe(response => {
      registerURL = response.register_url;
    });
    if (!go1Enabled) {
      this.router.navigate([registerURL]);
      return false;
    } else return true;
  }
}
