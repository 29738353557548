import { Component, OnInit } from '@angular/core';
import {apiEndPoints,config,environment,keywords,routingEndpoint} from "../../../../../../environments/root/environment";
import {ModalService} from "../../../../../erp-integration/service/modal.service";
import {MarketingPrefContactJsonRequest, MarketingPrefContactPayloadData,UpdateMarketingPrefContactRequest} from "../../../../model/response/marketing-pref";
import {map} from "rxjs/operators";
import {RequestService} from "../../../../../global/services/request/request.service";
import {TranslateService} from "@ngx-translate/core";
import {SiteService} from "../../../../../global/services/site/site.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-jp-marketing-pref-contact',
  templateUrl: './jp-marketing-pref-contact.component.html',
  styleUrls: ['./jp-marketing-pref-contact.component.css']
})
export class JpMarketingPrefContactComponent implements OnInit {
  isOpenModal: boolean;
  identifier: any;
  isSuccessMsg: boolean;
  isErrorMsg: boolean;
  isButtonDisable: boolean;
  private yesText: string;
  public marketingPrefContactPayloadData:MarketingPrefContactPayloadData;
  public errorRoute: string;
  SubscriptionList:Subscription[]=[];
  public consentValue: string;
  public updateClicked: boolean;
  constructor(private modalService: ModalService,private requestService: RequestService,private _translate: TranslateService,
              private siteService: SiteService,private activatedRoute: ActivatedRoute,private router: Router) {
    const activatedRouteQueryParamsSubscription= this.activatedRoute.queryParams.subscribe((params)=> {
      this.identifier=params['identifier'];
      console.log('this.identifier',this.identifier);
    });
    const translateGetTranslationYesTextSubscription = this._translate.getTranslation(siteService.getSiteLocale()).subscribe((res)=>{
      this.yesText = (res['yes_contact_text']);
    });
  }

  ngOnInit(): void {
    this.setRoute();
    //this.getMarketingPrefContact1();
    this.getMarketingPrefContact();

  }
  res1 ={
    "status": "HAYS_200",
    "message": "SUCCESS",
    "data": {
      "eshot" : "Y",
      "businessDev" : "Y","specCv": "Y","survey": "N"
    }
  }
  getMarketingPrefContact(){
    let jsonData= new MarketingPrefContactJsonRequest(this.identifier,this.router.url.split('?')[0]);
    const requestServicePostRequestGateWaySubscription= this.requestService.postRequestGateWay(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.getMarketingPreferencesContact,jsonData)
      .subscribe((res) => {
        if (res.status === 'HAYS_200') {
          if(res.data.statusCode === 'HAYS_410'){
            this.router.navigate([this.errorRoute]);
          }
          else  {
            this.marketingPrefContactPayloadData = res.data;

          }

        }

      }, (error) => {
        this.router.navigate([this.errorRoute]);
      });

  }

  getMarketingPrefContact1(){
    let jsonData= new MarketingPrefContactJsonRequest(this.identifier,this.router.url.split('?')[0]);
    if(this.res1.status =='HAYS_200'){
      this.marketingPrefContactPayloadData = this.res1.data;
    }
    else {
      this.router.navigate([this.errorRoute],{queryParams:{gdprSubmit:keywords.fail}});
    }
  }

  updateMarketingPrefContact(){
    this.isButtonDisable=true;
    this.updateClicked=true;
    let jsonData = new UpdateMarketingPrefContactRequest(this.identifier,this.marketingPrefContactPayloadData.eshot, this.marketingPrefContactPayloadData.businessDev,
      this.marketingPrefContactPayloadData.specCv,this.marketingPrefContactPayloadData.survey, this.router.url.split('?')[0]);
    const updateMarketingPrefRequestServicePostRequestGateWaySubscription= this.requestService.postRequestGateWay(config.theme,apiEndPoints.gateWayAPI,
      apiEndPoints.updateMarketingPreferencesContact,jsonData)
      .subscribe((res) => {
        if (res.status === config.SUCCESS && res.data) {
        //  this.getMarketingPrefContact();
          this.isSuccessMsg=true;
          this.isErrorMsg=false;
          this.openPopupSuccessErrorMsg();
        }
      }, (error) => {
        this.isSuccessMsg=false;
        this.isErrorMsg=true;
        // this.isButtonDisable=true;
        this.openPopupSuccessErrorMsg();
      });
    this.SubscriptionList.push(updateMarketingPrefRequestServicePostRequestGateWaySubscription);
    // }
  }

  setService(event,type){
    if(event.currentTarget.innerText===this.yesText)
    {
      this.consentValue='Y'
    }
    else
    {
      this.consentValue = 'N'
    }
    switch (type){
      case keywords.email:
        this.marketingPrefContactPayloadData.eshot =  this.consentValue;
        break;
      case keywords.bdcall:
        this.marketingPrefContactPayloadData.businessDev = this.consentValue;
        break;
      case keywords.speccv:
        this.marketingPrefContactPayloadData.specCv =  this.consentValue;
        break;
      case keywords.survey:
        this.marketingPrefContactPayloadData.survey =  this.consentValue;
        break;
    }

  }

  setRoute(){
    let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"];
    if(orgName){
      let base = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org];
      this.errorRoute = (base[routingEndpoint.errorPage]).replace(':orgName',orgName);
    }
    else{
      let base = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url];
      this.errorRoute = base[routingEndpoint.errorPage];
    }
  }
  openPopupSuccessErrorMsg() {
    this.isOpenModal = true;
    this.modalService.open('market_pref_contact_success_error_msg');
  }
  closeModal(id: string)
  {
    this.modalService.close(id);
    // this.isButtonDisable=false;
  }

}
