import {RequestService} from '../../../services/request/request.service';
import {
  APP_ID,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  SecurityContext,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {map} from 'rxjs/operators';
import {ChangeLanguage, ChangeLanguageAdapt, Language} from '../../../models/header/change-language';
import {SiteService} from '../../../services/site/site.service';
import {
  clientConfigRequest,
  HeaderAdapter,
  HeaderMenu,
  HeaderRequestJson,
  LeftTop,
  RightTop,
  UsefulLinkData
} from '../../../models/header/header-menu';
import {apiEndPoints, config, environment, routingEndpoint} from '../../../../../environments/root/environment';
import {TranslateService} from '@ngx-translate/core';
import {TokenStorage} from '../../../services/tokenStorage/token-storage';
import {DOCUMENT, isPlatformBrowser, isPlatformServer, PlatformLocation} from '@angular/common';
import {DataSharingService} from '../../../../jobportal/service/data-sharing.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ExtraFieldsConfig, GoogleJobResponse} from '../../../../jobportal/model/response/google-job-response';
import {JobSearchService} from '../../../../jobportal/service/job-search.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {PersonalDetails} from '../../../../jobportal/model/response/my-profile';
import {CookieService} from 'ngx-cookie-service';
import { ModalService } from 'src/app/erp-integration/service/modal.service';
import {DomSanitizer, Meta, Title, TransferState} from "@angular/platform-browser";
import { DynamicPageTitlesUtility } from 'src/app/jobportal/shared/utilitiy/dynamicPageTitles';
import { Subscription } from 'rxjs';
import { AzureGaService } from 'src/app/global/services/azure-ga/azure-ga.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers:[ChangeLanguageAdapt,HeaderAdapter],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit , OnDestroy{
  public languages:Language[];
  public liferaybaseUrl:string;
  public imageRedirectUrl:string;
  public imageUrl:string;
  public leftTopHeader:LeftTop[]=[];
  public rightTopHeader:RightTop[]=[];
  public usefullinks:UsefulLinkData[]=[];
  private mobileSearchClicked: boolean;
  private mobileMenuClicked: boolean;
  public alertCount:any;
  public myProfileRouterLink:any;
  public addThisData:string[];
  public loggedInUser=false;
  private extraFieldConfig: ExtraFieldsConfig;
  private callbackFunction=false;
  public hostName: string;
  private lastScroll: number;
  public stickyClass: boolean;
  public gtmmScript: string;
  public gtmCode: string;
  public baseUrl: string;
  public location: string="";
  public keyWord: string="";
  public jobCount: string;
  public emailAddress :string;
  @ViewChild('iframe', {static: false}) iframe :ElementRef;
  private personalDetails: PersonalDetails;
  public orgName: string;
  public cdnScript1: any;
  isAcceptedTnC: boolean;
  isOpenModal: boolean;
  isChecked: boolean = false;
  showSearch: boolean = false;
  public dburl:string;
  public dbresurl:string;
  SubscriptionList:Subscription[]=[];
  /**
   * Determines dynamic tags are enabled or not
   */
  public dynamicPageTitleMetaTags: boolean = false;
  /**
   * Captures dynamic header text shown underneath search boxes in search page based upon user search criteria
   */
  public dynamicSearchText: string = '';

  private countrySpecificLocale: any;
  htsBaseUrl: any;
  /**
   * If usefulprivacy links navigation item to be displayed or not in the page header
   */
  public isUsefulPrivacyEnabled: boolean = false;
  public valiateOrgName:string;
  /**
   * List of orgs for which useful privacy links is disabled
   */
  private usefulPrivacyDisabledOrgsCollection = ['yourrailindustryjob', 'geniuswithin'];
  /**
   * If home navigation link item to be displayed or not in the page header
   */
  public isHomeNavigationLinkEnabled: boolean = false;
  /**
   * List of orgs for which home navigation link is disabled
   */
  private homeNavigationDisabledOrgsCollection = ['yourrailindustryjob'];
  /**
   * If worldwide navigation link item to be displayed or not in the page header
   */
  public isWorldwideNavigationEnabled: boolean = false;
  /**
   * List of orgs for which worldwide navigation link is disabled
   */
  private worldwideNavigationDisabledOrgsCollection = ['yourrailindustryjob'];
  private siteBaseURL: string;
  public removePrivacyPolicyLinkTrustArc: boolean = false;
  marketingPrefContactURL:any;
  marketingPrefContactRoute: boolean;
  headerCountry:any;
  referrer : string;
  IsReferrer: string;
  constructor(private requestService: RequestService,private languageAdapt:ChangeLanguageAdapt,private siteservice:SiteService,
              private headerAdapter:HeaderAdapter, private translation:TranslateService,private tokenStorage:TokenStorage,private dataSharing:DataSharingService,
              private activateRoute:ActivatedRoute,private cookieService:CookieService, private modalService: ModalService,
              private renderer2:Renderer2,@Inject(DOCUMENT) private document: Document, public route:Router,private jobSearchService:JobSearchService, private deviceDetector:DeviceDetectorService, private sanitized: DomSanitizer,
              private state: TransferState, @Inject(PLATFORM_ID) private platformId: object,
              @Inject(APP_ID) private appId: string, private platformLocation: PlatformLocation, private titleService: Title, private metaService: Meta,private _siteService: SiteService,
              private changeDetectorRef: ChangeDetectorRef,
              private gaService: AzureGaService) {
    if(isPlatformServer(this.platformId)){
      this.hostName = this.platformLocation.hostname;
    }else {
        this.hostName = location.hostname;
    }
    this.orgName = this.route.routerState.snapshot.root.firstChild.params["orgName"];
    this.baseUrl=config.baseWebAppUrl+'/prjs';
    this.liferaybaseUrl=environment[config.theme+this.siteservice.getSiteLocale()][apiEndPoints.liferaybaseUrl];
    this.siteBaseURL = environment[config.theme + this.siteservice.getSiteLocale()]['siteBaseURL'];
    this.htsBaseUrl = environment[config.theme+this.siteservice.getSiteLocale()][apiEndPoints.htsBaseUrl];
    const urlParams = new URL(window.location.href);
    this.valiateOrgName = urlParams.pathname.split('/')[1];
    this.getClientConfig();
    this.translation.setDefaultLang(this.siteservice.getSiteLocale());
   const translationGetTranslationSubscription = this.translation.getTranslation(this.siteservice.getSiteLocale()).subscribe(response => {
      this.countrySpecificLocale = response;
    });
   

    const jobSearchServiceSharedCountSubscription = this.jobSearchService.sharedCount.subscribe(count => this.jobCount = count);
    const jobSearchServiceSharedKeyWordSubscription = this.jobSearchService.sharedKeyWord.subscribe((keyWord) => {
      this.keyWord = keyWord;
      if(this.dynamicPageTitleMetaTags)
        this.generateUserSearchSpecificHeader();
    });
    const jobSearchServiceSharedLocationSubscription = this.jobSearchService.sharedLocation.subscribe((location) => {
      this.location = location;
      if(this.dynamicPageTitleMetaTags)
        this.generateUserSearchSpecificHeader();
    });

    this.gtmCode = environment[config.theme + this.siteservice.getSiteLocale()]['config']['gtm_code'];
    this.showSearch=environment[config.theme+this.siteservice.getSiteLocale()]['config']['showSearch'];
    this.dynamicPageTitleMetaTags = environment[config.theme+this.siteservice.getSiteLocale()]['config']['dynamicPageTitleMetaTags'];
    this.myProfileRouterLink=environment[config.theme + this.siteservice.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.myProfile];
    if(this.orgName)
      this.myProfileRouterLink = (environment[config.theme + this.siteservice.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.myProfile]).replace(':orgName',this.orgName);
    if(this.tokenStorage.getLoggedInToken()) {
      this.loggedInUser = true;
      this.personalDetailsResponse();
    }
    // Haystech redirection one locale to another locale for SE
    if (this.siteservice.getDomain() === 'SE' && this.siteservice.getOrgName() === 'it'  && this.siteservice.getLocale() === 'sv' ) {
      let lang ='';
        const queryParams = { lang: 'en' };

        this.route.navigate(
          [],
          {
            relativeTo: this.activateRoute,
            queryParams: queryParams,
            queryParamsHandling: 'merge',
          }).then(() => {
         // window.location.reload();

         localStorage.setItem('locale', 'en');
         localStorage.setItem('siteLocale', 'en_SE');
         window.location.href =  window.location.href;
        });
    }
    if(this.siteservice.getDomain() && this.siteservice.getDomain() === 'TH' &&  window.location.href.indexOf('recruiting-now') > -1){
      this.route.navigate(["/"]).then(result=>{window.location.href = `${this.liferaybaseUrl}/register-a-vacancy`});
    }
    this.SubscriptionList.push(translationGetTranslationSubscription);
    this.SubscriptionList.push(jobSearchServiceSharedCountSubscription);
    this.SubscriptionList.push(jobSearchServiceSharedKeyWordSubscription);
    this.SubscriptionList.push(jobSearchServiceSharedLocationSubscription);
    


  }
  private personalDetailsResponse(){
   const personalDetailsResponseRequestServiceGetRequestSubscription = this.requestService.getRequest(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.getpersonaldetails,null,null).subscribe(
      (res)=>{
        if(res.data && res.status==config.SUCCESS)
          this.personalDetails=res.data;
        this.emailAddress=this.personalDetails.email;
        this.dataSharing.setPersonalDetails(this.personalDetails);
        let cookieDomainName = environment[config.theme + this.siteservice.getSiteLocale()][apiEndPoints.cookieDomain];
        if(!this.cookieService.get('usrname')){
          this.cookieService.set('usrname', this.personalDetails.name, 0, '/', cookieDomainName);
        }
        if(!this.cookieService.get('PortalId')){
          this.cookieService.set('PortalId',this.personalDetails.portalProfileId,0,'/',cookieDomainName);
        }
      },(error)=>{
      });
      this.SubscriptionList.push(personalDetailsResponseRequestServiceGetRequestSubscription);

  }

  getClientConfig(){
    let payload = new clientConfigRequest(this.orgName==this.valiateOrgName? location.origin +'/'+ this.orgName+'/' : (this.orgName? location.origin +'/'+ this.valiateOrgName + '/' +this.orgName+'/' : location.origin+'/'));
      //let payload = new clientConfigRequest(this.orgName? location.origin +'/'+ this.orgName+'/' : location.origin+'/');
      if(isPlatformServer(this.platformId))
       payload = new clientConfigRequest(this.orgName? 'https://'+this.hostName+'/'+this.orgName+'/' : 'https://'+this.hostName+'/');
       if(this.hostName == 'localhost')
        payload = new clientConfigRequest(this.orgName? this.hostName+'/'+this.siteservice.getDomain().toUpperCase()+'/'+this.orgName+'/'  : this.hostName+'/'+this.siteservice.getDomain().toUpperCase()+'/');
      const requestServicePostRequestGateWaySubscription = this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.getClientConfig, payload)
        .subscribe(response => {
          if (response.status === config.SUCCESS && response.data) {
            this.dataSharing.sendClientConfig(response.data);
            this.imageRedirectUrl=response.data.logoRedirectUrl;
            //this.imageUrl = response.data.logoImageUrl;
            this.imageUrl = this.deviceDetector.isMobile()? response.data.logoImageMobileUrl: response.data.logoImageUrl;
            if(this.orgName === 'yourrailindustryjob'){
              this.liferaybaseUrl = `${environment[config.theme + this.siteservice.getSiteLocale()][apiEndPoints.liferayclientBaseUrl]}/yourrailindustryjob`;
            }
            else{
              this.liferaybaseUrl =  response.data.logoRedirectUrl;
            }

          }
        }, error => {
        });
        this.SubscriptionList.push(requestServicePostRequestGateWaySubscription);
    this.marketingPrefContactURL = environment[config.theme + this._siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.marketingPrefContactPage];
  console.log('this.marketingPrefContactURL',this.marketingPrefContactURL);
  if(this.marketingPrefContactURL != undefined){ 
  if (this.route.url.indexOf(this.marketingPrefContactURL) > -1) {
      this.marketingPrefContactRoute = true;

    }
  }
  }

  ngOnInit() {
    if(isPlatformServer(this.platformId)){
      if(this.gtmCode) {
      this.gtmmScript="(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});" +
        "var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';" +
        "j.async=true;j.src='//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})" +
        "(window,document,'script','dataLayer','"+this.gtmCode+"');";
        let script = this.renderer2.createElement('script');
        script.text = `${this.gtmmScript}`;
        this.renderer2.insertBefore(this.document.head, script,this.document.head.firstChild)
        let noscript = this.renderer2.createElement('noscript');
        let iframe = this.renderer2.createElement('iframe');
        iframe.src = "//www.googletagmanager.com/ns.html?id=" + this.gtmCode;
        iframe.width = "0";
        iframe.height = "0";
        iframe.style = "display:none;visibility:hidden";
        noscript.appendChild(iframe);
        this.renderer2.insertBefore(this.document.body, noscript, this.document.body.firstChild);
        this.addAppleItunesMeta();
      }
    }
    this.isUsefulPrivacyEnabled = this.determineEnabledDisabledLinks(this.usefulPrivacyDisabledOrgsCollection);
    this.isHomeNavigationLinkEnabled = this.determineEnabledDisabledLinks(this.homeNavigationDisabledOrgsCollection);
    this.isWorldwideNavigationEnabled = this.determineEnabledDisabledLinks(this.worldwideNavigationDisabledOrgsCollection);
     // this.checkCaptchaDisabled();
      this.getHeaderMenu();
      this.getAlertCount();
      if(this.dynamicPageTitleMetaTags)
        this.getInitialHeaderTagContent();
      this.setPageMetaTag();
    this.removePrivacyPolicyLinkTrustArc = environment[config.theme + this.siteservice.getSiteLocale()]['config']['removePrivacyPolicyLinkTrustArc']? true : false;
    
   this.IsReferrer = environment[config.theme + this.siteservice.getSiteLocale()]['config']['IsReferrer'];
   this.referrer = document.referrer;
   if(this.referrer && this.referrer !== null && this.referrer !== undefined){
     const referrerValue = this.cookieService.get('referrer');
     if(!referrerValue) {
      const currentTime = new Date();
      currentTime.setHours(currentTime.getHours() + 24);
      this.cookieService.set('referrer',this.referrer.split('/')[2], currentTime);
      console.log("referrer" + this.referrer.split('/')[2]);
      console.log("Referrer expire time " + currentTime);
     }
   } else {
    this.cookieService.set('referrer', this.IsReferrer);
   }
  }
  /**
   * Method to check if the orgname exists in the useful privacy disabled links collection
   * @param orgsCollection
   * @returns
   */
  private determineEnabledDisabledLinks(orgsCollection){
    if(orgsCollection.indexOf(this.orgName) > -1){
      return false;
    }
    return true;
  }

  /*private checkCaptchaDisabled() {
    const isCaptchaValue = environment[config.theme+this.siteservice.getSiteLocale()]['config']['isCaptchaValueEnabled'];
    console.log('Captcha Value',isCaptchaValue);
    localStorage.setItem("disableCaptcha",isCaptchaValue);
    this.dataSharing.setIsCaptchaDIsabled(isCaptchaValue.toString());
    // this.SubscriptionList.push(isCaptchaValue);

  }*/

  showDropDown={
    showData: false
  };

  redirectTo(url){
    window.location.href=url;
  }

  openRoutingUrl(url) {
    let isClientUrl;
    config.ExpertClientsName.forEach(a => { if (url == (a.concat('/'))) isClientUrl = true; })
    if (isClientUrl) {
      this.isOpenModal = true;
      this.modalService.open('send_cv_confirmation');
    } else {
      if (url.indexOf('jobportal/') > -1) {
        url = this.baseUrl + url;
        this.sendClassToMainContainer('');
        if (url.indexOf('/s/signout/submit/logout') > -1) {
          url = url.replace('/s/signout/submit/logout', '/as/user/submit/login');
          this.loggedInUser = false;
          url = url + "?logout=success";
        }
      } else if (url.indexOf('/logout') > -1 && url.indexOf('onmicrosoft.com') === -1) {
        let param = '/logout';
        const logOutUrl = environment[config.theme + this.siteservice.getSiteLocale()][routingEndpoint.routing_url]['logOutPage'];
        if(logOutUrl){
          param = logOutUrl
        }
       
        if (this.orgName)
          param = '/' + this.orgName + param;
        url = window.location.origin + param;
      }
      if(url.indexOf("prjsauthapp")>-1 && this.route.url.indexOf("apply")==-1 && this.route.url.indexOf("/partner/go1/")==-1 && this.route.url.indexOf("Job/Details")==-1 && url.indexOf("logout")==-1) {
        debugger;
        localStorage.setItem("requstedRedirectURL", window.location.href);
        url=url+"?redirectBackURL="+window.location.pathname;
      }
      if(url.indexOf('action=logout')>-1){
       const requestServiceGetRequestActionLogoutSubscription = this.requestService.getRequest(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.logoutUser,null,null).pipe().
        subscribe(response => {
          this.tokenStorage.clearCacheAndCookie();
          window.location.href = url;
        },error => {
          this.tokenStorage.clearCacheAndCookie();
          window.location.href = url;
        });
        this.tokenStorage.clearCacheAndCookie();
        this.SubscriptionList.push(requestServiceGetRequestActionLogoutSubscription);
      }
      window.location.href = url;
    }
  }

  public sendClassToMainContainer(data){
    if(data==='search-active') {
      this.mobileSearchClicked = !this.mobileSearchClicked;
      this.dataSharing.sendMainComponentData(this.mobileSearchClicked ? data : '');
    }else{
      this.mobileMenuClicked = !this.mobileMenuClicked;
      if(!this.mobileMenuClicked)
        this.mobileSearchClicked=false;
      this.dataSharing.sendMainComponentData(this.mobileMenuClicked ? data : '');
    }
  }

  private getLanguages(){
   const getLanguagesRequestServiceGetRequestSubscription = this.requestService.getRequest(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.countryLanguage).pipe(
      map((res:ChangeLanguage)=> this.languageAdapt.adapt(res)))
      .subscribe((res)=>{
        if(res.status==config.SUCCESS && res.data)
          this.languages=res.data;
      },(error)=>{
      });
      this.SubscriptionList.push(getLanguagesRequestServiceGetRequestSubscription);

  }

  private getAlertCount(){
    const dataSharingSharedUpcomingInterviewDataSubscription = this.dataSharing.sharedUpcomingInterviewData.subscribe((res)=>{
      this.alertCount=res;
    })
    this.SubscriptionList.push(dataSharingSharedUpcomingInterviewDataSubscription);
  }

  private getHeaderMenu(){
    const getHeaderMenuActivateRouteQueryParamsSubscription= this.activateRoute.queryParams.subscribe((params)=>{
      if(params['ChangeEmail']=='Y' || params['ChangeEmail']=='N' || params['ChangePassword'] == 'Y' || params['ChangePassword'] == 'N' || params['logout'] == 'success' ||
        params['q'] == 'unauthorised' || params['signed']==='Y') {
        this.loggedInUser=false;
        this.dataSharing.sharedHeaderData.subscribe((res) => {
          this.usefullinks = [];
          this.rightTopHeader = [];
          this.leftTopHeader = [];
          if (res){
            this.callbackFunction=true;
            if(res.data['PageData']['Menu']['RightTop']) {
              res.data['PageData']['Menu']['RightTop'].forEach((item) => {
                if (this.orgName)
                {
                  this.dburl=item.resource_url.split('/')[1];
                  this.dbresurl=item.resource_url.replace(item.resource_url.split('/')[1],this.orgName); 
                  item.resource_url=this.dburl + this.dbresurl;
                }
                  //item.resource_url = this.orgName + item.resource_url;
                this.rightTopHeader.push(item);
              });
              if(isPlatformBrowser(this.platformId)){
                this.getAzureLoginURL();
              }
            }
            if(res.data['UsefulLinkData'])
                this.usefullinks = res.data['UsefulLinkData']['results'];
                if( res.data['PageData']['Menu']['LeftTop']) {
          res.data['PageData']['Menu']['LeftTop'].forEach((item) => {
            if ((item['resource_url'].indexOf('online-learning') > -1) || (item['resource_url'].indexOf('salary-checker') > -1) || (item['resource_url'].indexOf('hays-connect') > -1) || (item['resource_url'].indexOf('customer-service') > -1)
              || (item['resource_url'].indexOf('about-hays') > -1) || (item['resource_url'].indexOf('what-we-offer') > -1)|| (item['resource_url'].indexOf('life-at-hays') > -1)) {
             /* if(this.orgName)
                item.resource_url = this.liferaybaseUrl +'/'+this.orgName+ item.resource_url;
              else*/
                item.resource_url = this.liferaybaseUrl + item.resource_url;
            }
            else{
              if(this.orgName)
              {
                this.dburl=item.resource_url.split('/')[1];
                this.dbresurl=item.resource_url.replace(item.resource_url.split('/')[1],this.orgName); 
                item.resource_url=this.dburl + this.dbresurl;
              }
              
                //item.resource_url = this.orgName+item.resource_url;
            }
            this.leftTopHeader.push(item);
          });
        }
        }
        })

      }
      this.SubscriptionList.push(getHeaderMenuActivateRouteQueryParamsSubscription);

    });

    let headerJson= new HeaderRequestJson(
      '',
      environment[config.theme + this.siteservice.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.header]
    );
    let endPoint=apiEndPoints.pagemanagement;
    if(!this.callbackFunction) {
      const requestServicePostRequestGateWayHeaderMenuSubscription = this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, endPoint, headerJson).pipe(
        map((res: HeaderMenu) => this.headerAdapter.adapt(res)))
        .subscribe((res) => {
          this.usefullinks = [];
          this.rightTopHeader = [];
          this.leftTopHeader = [];
          if (res.status == config.SUCCESS && res.data) {
           // this.dataSharing.sendHeaderData(res);
            if(res.data['PageData']['Menu']['RightTop']) {
              res.data['PageData']['Menu']['RightTop'].forEach((item) => {
                if(item.resource_url.indexOf('http') > -1 && item.resource_url.indexOf(this.siteBaseURL) == -1) {
                //Replace domain and hostname for PP staging environment(testsg-> webtestsg) 
                  const domainToBeChange = item.resource_url.substring(item.resource_url.indexOf('//') + 2,item.resource_url.indexOf('.'));
                  const currentDomain =  this.siteBaseURL.substring(this.siteBaseURL.indexOf('//') + 2,this.siteBaseURL.indexOf('.'));
                  item.resource_url = item.resource_url.replaceAll(domainToBeChange, currentDomain);
                }
                if (this.orgName && item.resource_url.indexOf('http')===-1)
                  
                  //item.resource_url = this.orgName + item.resource_url;
                  if((this.orgName==this.valiateOrgName))
                  {
                    item.resource_url = this.orgName + item.resource_url;
                  }
                  else
                  {
                    item.resource_url= '/'+ item.resource_url.split('/')[1]  + item.resource_url.replace(item.resource_url.split('/')[1],this.orgName); 

                  }
                  
                if(this.orgName && item.resource_url.indexOf('http')>-1) {
                  if((this.hostName.indexOf('testsg')>-1) || (this.hostName.indexOf('mcoat')>-1) )
                  {
                    item.resource_url = item.resource_url.indexOf('&client=hays&')>-1 ? item.resource_url.replace('&client=hays&','&client='+this.orgName+'&'):item.resource_url;
                  }
                  else
                  {
                    item.resource_url = item.resource_url.indexOf('/hays/')>-1 ? item.resource_url.replace('/hays/','/'+this.orgName+'/'):item.resource_url;
                  }

                }
                this.rightTopHeader.push(item);
              });
              if(isPlatformBrowser(this.platformId)){
                this.getAzureLoginURL();
              }
            }
            if (res.data['UsefulLinkData'])
              this.usefullinks = res.data['UsefulLinkData']['results'];
              if(res.data['PageData']['Menu']['LeftTop']) {
            res.data['PageData']['Menu']['LeftTop'].forEach((item) => {
              if(this.orgName && this.orgName === 'yourrailindustryjob' && !(item['resource_url'].indexOf('salary-checker') > -1) && !(item['resource_url'].indexOf('shortlisted-jobs')>-1) && !(item['resource_url'].indexOf('/search') > -1)){
                item.resource_url = item.resource_url;
              }
              else if(this.orgName && this.orgName === 'geniuswithin' && !(item['resource_url'].indexOf('/search') > -1)){
                item.resource_url = item.resource_url;
              }
              else if ((item['resource_url'].indexOf('online-learning') > -1) || (item['resource_url'].indexOf('salary-checker') > -1) || (item['resource_url'].indexOf('hays-connect') > -1) || (item['resource_url'].indexOf('customer-service') > -1)
                || item['resource_url'].indexOf('about-hays')>-1 || item['resource_url'].indexOf('what-we-offer')>-1 || item['resource_url'].indexOf('life-at-hays')>-1) {

                  if(this.orgName == config.HaysHTS)
                item.resource_url = this.htsBaseUrl + item.resource_url;
              else
                item.resource_url = this.liferaybaseUrl + item.resource_url;
              }
              else{
                if(this.orgName)
                item.resource_url = (this.orgName == config.HaysHTS && item['resource_url'].indexOf('shortlisted-jobs')>-1) ? this.orgName + item.resource_url
                                :this.orgName == config.HaysHTS ? this.htsBaseUrl + item.resource_url
                                :((this.orgName==this.valiateOrgName)) ? this.orgName + item.resource_url : '/'+ item.resource_url.split('/')[1]  + item.resource_url.replace(item.resource_url.split('/')[1],this.orgName); //this.orgName + item.resource_url;
              }
              this.leftTopHeader.push(item);
            });
            this.liferaybaseUrl=environment[config.theme+this.siteservice.getSiteLocale()][apiEndPoints.liferaybaseUrl];
          }
          }
        })
        this.SubscriptionList.push(requestServicePostRequestGateWayHeaderMenuSubscription);

    }
  }
  @HostListener('window:scroll')
  onWindowScroll() {
    var scroll = window.pageYOffset;
    this.stickyClass = scroll < this.lastScroll;
    if(scroll < 100){
      this.stickyClass= false;
    }
    this.lastScroll = scroll;
  }

  ngAfterViewInit(){
    this.addScripts();
    this.changeDetectorRef.detectChanges();
  }

  private addScripts() {
    let isZoopimEnabled =environment[config.theme + this.siteservice.getSiteLocale()]['config']['zopim_enabled'];
    if(isZoopimEnabled){
      let zoopimEnabledDevices = environment[config.theme + this.siteservice.getSiteLocale()]['config']['zopim_enabled_device'];
      let zoopimEnabledDevicesList :string [];
      zoopimEnabledDevicesList = zoopimEnabledDevices.split(',');
      let isDesktopDevice = this.deviceDetector.isDesktop(window.navigator.userAgent);
      let isMobileEnabled =this.deviceDetector.isMobile(window.navigator.userAgent);
      let isTablet =this.deviceDetector.isTablet(window.navigator.userAgent);
      let userAgent = this.jobSearchService.getUserAgent(window.navigator.userAgent);
      for(let i=0;i<zoopimEnabledDevicesList.length;i++){
        if((zoopimEnabledDevicesList[i]==='-Desktop' && isDesktopDevice) || (zoopimEnabledDevicesList[i]==='-Mobile' && isMobileEnabled) ||
          (zoopimEnabledDevicesList[i]==='-Tablet' && isTablet)){
          let zopimScript = this.renderer2.createElement('script');
          zopimScript.src=environment[config.theme + this.siteservice.getSiteLocale()]['config']['zopim_key_withURL'];
          zopimScript.async=`async`;
          this.renderer2.appendChild(this.document.head, zopimScript);
          break;
        }
      }
    }
    const requestServiceGetRequestSubscription = this.requestService.getRequest(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.jobflowconfig, null, null).subscribe(res => {
      if (res.status == config.SUCCESS) {
        this.extraFieldConfig = res.data;
        this.jobSearchService.setExtraConfigField(this.extraFieldConfig);
        let trustarc_hostname=environment[config.theme+this.siteservice.getSiteLocale()][apiEndPoints.cookieBannerDomain];
        const isAutoBlockScriptEnabled = environment[config.theme + this.siteservice.getSiteLocale()]['config']['addAutoBlockAssetScriptEnabled'];
        const isAutoBlockOptOutScriptEnabled = environment[config.theme + this.siteservice.getSiteLocale()]['config']['addAutoBlockOptOutScriptEnabled'];
        if(trustarc_hostname)
          trustarc_hostname=trustarc_hostname.substr(1);
        let lang = this.siteservice.getLocale();
        if(lang === 'br'){
          lang = 'pt';
          
        }
        if(trustarc_hostname) {
          let trustarc_script = this.renderer2.createElement('script');
         
          if(this.removePrivacyPolicyLinkTrustArc) {
            trustarc_script.src = `//consent.trustarc.com/notice?domain=` + trustarc_hostname + `&c=teconsent&js=nj&noticeType=bb&gtm=1&text=true`;
          }
          else {
            trustarc_script.src = `//consent.trustarc.com/notice?domain=` + trustarc_hostname + `&c=teconsent&js=nj&noticeType=bb&gtm=1&text=true&privacypolicylink=`
              + this.liferaybaseUrl + this.extraFieldConfig.gdprPolicyLink + `&language=` + lang;
          }
          trustarc_script.async = `async`;
          trustarc_script.crossorigin = `crossorigin`;
        
          /**
           * Feature# - 70560, 70295
           * Checks if the autoBlockOptOutScript is enabled for the country
           * If enabled then calls the function to add the script
           */
          // isAutoBlockOptOutScriptEnabled && this.addAutoBlockOptOutScript(trustarc_hostname);
          /**
           * Feature# - 70560, 70295
           * Checks if the autoBlockScript is enabled for the country
           * If enabled then calls the function to add the script
           */
          // isAutoBlockScriptEnabled && this.addAutoBlockAssetScript(trustarc_hostname);
          this.renderer2.insertBefore(this.document.head, trustarc_script, this.document.head.firstChild);
        }
        this.checkChangeLanguageDisable(this.extraFieldConfig.disableChangeLangDropDown);
      }
      this.SubscriptionList.push(requestServiceGetRequestSubscription);
    });


  }

  changeLanguage(event)
  {
    this.liferaybaseUrl=environment[config.theme+this.siteservice.getSiteLocale()][apiEndPoints.liferaybaseUrl];
    if(event.target.value && event.target.value.indexOf('update_language') > -1) {
      const pageUrl = this.route.routerState.snapshot.url;
      if(pageUrl.indexOf('update-your-privacy') > -1){
        this.handelUpdateYourPrivacyPageChangeLang(event);
      }else {
        let url = this.liferaybaseUrl + event.target.value ;
        window.location.href= url ;
      }
    }
    else {
      const currentUrl = this.route.url.split('?')[0];
      let allRoutePaths = '';
      allRoutePaths = environment[config.theme + this.siteservice.getSiteLocale()][routingEndpoint.routing_url];
      if(this.orgName) {
        allRoutePaths = environment[config.theme + this.siteservice.getSiteLocale()][routingEndpoint.routing_url_org];
      }
      const currentPathName = this.getKeyByValue(allRoutePaths,currentUrl);
      const siteLocale =  event.target.value +'_' + this.siteservice.getDomain();
      const queryParams = { lang: event.target.value  };
      let routeUrls = environment[config.theme + siteLocale][routingEndpoint.routing_url];
      if(this.orgName){
         routeUrls = environment[config.theme + siteLocale][routingEndpoint.routing_url_org];
      }
      let routeUrl = '';
      if(currentPathName) {
       routeUrl = routeUrls[currentPathName];
      }else {
        const searchTerm = this.route.routerState.snapshot.root.children[0].children[0].params['searchTerm'];
       if(searchTerm){
       let keyword = '';
       let location = '';
       if(this.route.routerState.snapshot.root.queryParams['q']){
        keyword = this.route.routerState.snapshot.root.queryParams['q'];
       }
       if(this.route.routerState.snapshot.root.queryParams['location']){
        location = this.route.routerState.snapshot.root.queryParams['location']
       }
       if(this.keyWord && this.location) {
        routeUrl = routeUrls['searchLinkInLocation'].replace('{jobTitle}',this.convertTitleToSlug(this.keyWord)).replace('{location}',this.convertLocationToSlug(this.location));
      }else if(this.keyWord) {
        routeUrl = routeUrls['searchLinkTitle'].replace('{jobTitle}',this.convertTitleToSlug(this.keyWord));
      }else if(this.location){
        routeUrl = routeUrls['searchLinkLocation'].replace('{location}',this.convertLocationToSlug(this.location));
      }else {
        routeUrl = routeUrls[currentPathName]?routeUrls[currentPathName]:currentUrl;
      }

      }else {
          routeUrl = routeUrls[currentPathName]?routeUrls[currentPathName]:currentUrl;
      }
      }
      if(this.orgName){
        routeUrl = routeUrl.replace(':orgName',this.orgName);
      }
      if(this.route.url.split('?')[1]){
        routeUrl = routeUrl +'?'+this.route.url.split('?')[1]
      }
      const urlParams = new URL(window.location.origin+routeUrl);
      urlParams.searchParams.set('lang',event.target.value);
      window.location.href =  urlParams.href;
      // this.route.navigate(
      //   [],
      //   {
      //     relativeTo: this.activateRoute,
      //     queryParams: queryParams,
      //     queryParamsHandling: 'merge',
      //   }).then(() => {
      //     window.location.reload();
      //   });
    }
  }
   getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value.replace(':orgName',this.orgName));
  }

  handelUpdateYourPrivacyPageChangeLang(event){
    const locale = event.target.value.indexOf('en_CN') > -1 ? 'en':'cn'
    const queryParams = { lang: locale  };
    this.route.navigate(
      [],
      {
        relativeTo: this.activateRoute,
        queryParams: queryParams,
        queryParamsHandling: 'merge',
      }).then(() => {
        //window.location.reload();
        window.location.href =  window.location.href;
      });
  }
  getConsentChecked($event: boolean) {
    this.isChecked = $event;
  }
  closeModal(id: string)
  {
    this.modalService.close(id);
    this.isChecked = false;
  }
  sendCV(id) {
    config.ExpertClientsName.forEach(a => {
      if (this.orgName == a) {
      this.isChecked = false;
      this.modalService.close(id);
      window.location.href = this.orgName == 'careers'? "mailto:UKI.careers@hays.com?subject=Hays UK Careers CV Submission" : "mailto:HTSUK.Careers@hays.com?subject=HTS HAYS UK CAREERS CV SUBMISSION";
    }
  })

  }

  private checkChangeLanguageDisable(isDisable: boolean){
    if(isDisable) {
      const pageUrl = this.route.routerState.snapshot.url;
      const recordId = this.route.routerState.snapshot.root.children[0].children[0].params['recordId'];
      if(pageUrl.indexOf('Job/Detail') === -1 && pageUrl.indexOf('job/detail') === -1 && (pageUrl.indexOf('job/') === -1 && pageUrl.indexOf('/apply') === -1) && !recordId){
        this.getLanguages();
      }
    }else {
      this.getLanguages();
    }
  }
  /**
   * Method to retrieve user specific search header
   */
  private generateUserSearchSpecificHeader(){
    const splitwithoutspace=environment[config.theme + this.siteservice.getSiteLocale()]['config']['splitwithoutspace']?environment[config.theme + this.siteservice.getSiteLocale()]['config']['splitwithoutspace']:false;
    if(this.countrySpecificLocale){
      this.dynamicSearchText = DynamicPageTitlesUtility.getContentBasedOnSearch(this.location, this.keyWord, environment[config.theme+this.siteservice.getSiteLocale()]['config']['country_name'], this.countrySpecificLocale, 'headerH1Tag',splitwithoutspace);
    }
  }
  /**
   * Extracts location and job title from URL and sets the header
   */
  private getInitialHeaderTagContent(){
    if(this.platformLocation.href.indexOf('/search') > -1){
      const routeURL = this.platformLocation.search;
      const urlParams = new URLSearchParams(routeURL);
      if(urlParams){
        if(urlParams.get('location')) {
          this.location = urlParams.get('location');
        }
        if(urlParams.get('q')){
          this.keyWord = urlParams.get('q');
        }
        this.generateUserSearchSpecificHeader();
      }
    }
  }
  setPageMetaTag() {

    const routeURL = decodeURIComponent(this.platformLocation.href);
    const searchUrl = environment[config.theme+this.siteservice.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.search];
    const searchUrlTitle = environment[config.theme+this.siteservice.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.searchLinkTitle];
    const searchUrlLoc = environment[config.theme+this.siteservice.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.searchLinkLocation];
    if (routeURL && (routeURL.indexOf(searchUrl) > -1 || routeURL.indexOf(searchUrlTitle) > -1 || routeURL.indexOf(searchUrlLoc) > -1)){
     const setPageMetaTagJobSearchServiceSharedConfigList = this.jobSearchService.sharedConfigList.subscribe(domainConfig => {
        if (domainConfig) {
          this.setSearchPageMetaTags(this.siteBaseURL);
        }
      });
      this.SubscriptionList.push(setPageMetaTagJobSearchServiceSharedConfigList);
    }else {
      this.setOtherPageMetaTag(this.siteBaseURL);
    }
  }

  setOtherPageMetaTag(siteBaseURL:string) {
    const currentUrl = this.route.url.split('?')[0];
    console.log("Curent path :",currentUrl);
    const allRoutePaths = environment[config.theme + this.siteservice.getSiteLocale()][routingEndpoint.routing_url];
    console.log("All Routing Paths :",allRoutePaths);
    const currentPathName = this.getKeyByValue(allRoutePaths,currentUrl);
    console.log("Curr path ",currentPathName);
   const requestTransalationSubscription =  this.translation.getTranslation(this.siteservice.getSiteLocale()).subscribe((res)=>{
      const metaKey = currentPathName+'_meta';
      if(res && res[metaKey]){
        const author = res['other_page_title'];
        const type = res['header_hays_publication_events_text'];
        const metaTitle = res[metaKey]['title'];
        const metaDescription = res[metaKey]['description'];
        const pageUrl = siteBaseURL + this.platformLocation.pathname;
        const sharedImageUrl = siteBaseURL +'/assets/images/hays-og-optimize-image-new.png';
        const tags = [
          {property: 'og:title', content: metaTitle},
          {name: 'description', content: metaDescription},
          {property: 'og:description', content: metaDescription},
          {name: 'author', content: author},
          {property: 'og:url', content: pageUrl},
          {property: 'og:type', content: type},
          {property: 'og:image', content: sharedImageUrl},
          {property: 'og:image:width', content: '500'}
        ];
        this.metaService.addTags(tags);
        this.updateCanonicalUrl(pageUrl);
      }
    });
    this.SubscriptionList.push(requestTransalationSubscription);
  }

  /*setPageMetaTags(){
    const routeURL =  this.platformLocation.href;
    if (routeURL && routeURL.indexOf('/search') > -1) {
      this.jobSearchService.sharedConfigList.subscribe(domainConfig => {
    if(domainConfig){
      this.setSearchPageMetaTags();
    }
    });
    }else if(routeURL && (routeURL.indexOf('/job/browse/details') > -1 || routeURL.indexOf('/Job/Detail') > -1 || routeURL.indexOf('/SeekAuth') > -1)){
    this.jobSearchService.sharedJobDetailData.subscribe(jobDetailData=> {
      if(jobDetailData){
        this.setDetailsPageMetaTags(jobDetailData);
      }
    });
    }else {
      const siteBaseURL = environment[config.theme + this.siteservice.getSiteLocale()]['siteBaseURL'];
      const metaURL =  siteBaseURL + this.platformLocation.pathname;
      this.updateCanonicalUrl(metaURL);
    }
  }
  setDetailsPageMetaTags(googleJobResponse:GoogleJobResponse[]){
    if(googleJobResponse && googleJobResponse.length > 0) {
      this.translation.getTranslation(this.siteservice.getSiteLocale()).subscribe((res)=>{
      const title = googleJobResponse[0].JobTitle+' '+res['page_title'];
      const jobDescription =  googleJobResponse[0].SearchTextSnippet;
      const author = res['page_title'];
      const siteBaseURL = environment[config.theme + this.siteservice.getSiteLocale()]['siteBaseURL'];
      let sharedImageUrl = '';
      const metaURL =  siteBaseURL + this.platformLocation.pathname;
      sharedImageUrl = siteBaseURL +'/assets/images/hays-og-optimize-image-new.png';
      const type = res['hays_publication_events_text'];
      this.titleService.setTitle(title);
      const tags = [
        {property: 'og:title', content: title},
        {name: 'description', content: jobDescription},
        {property: 'og:description', content: jobDescription},
        {name: 'author', content: author},
        {property: 'og:url', content: metaURL},
        {property: 'og:type', content: type},
        {property: 'og:image', content: sharedImageUrl}
      ];
      if(siteBaseURL && siteBaseURL.indexOf('response') > -1) {
        tags.push({property: 'og:image:width', content: '200'});
        tags.push({property: 'og:image:width', content: '200'});
      }else {
        tags.push({property: 'og:image:width', content: '382'});
        tags.push({property: 'og:image:width', content: '200'});
      }
      this.metaService.addTags(tags);
      this.updateCanonicalUrl(metaURL);
      });
    }
  }*/
  updateCanonicalUrl(url:string){
    const head = this.document.getElementsByTagName('head')[0];
    var element: HTMLLinkElement= this.document.querySelector(`link[rel='canonical']`) || null
    if (element==null) {
      element= this.document.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','canonical')
    element.setAttribute('href',url)
  }

  setSearchPageMetaTags(siteBaseURL){
    const setSearchPageMetaTagsAtivateRouteQueryParamsSubscription= this.activateRoute.queryParams
    .subscribe(urlParams => {
      const setSearchPageMetaTagstranslationGetTranslationSubscription= this.translation.getTranslation(this.siteservice.getSiteLocale()).subscribe((res)=>{
        const author = res['page_title'];
        const type = res['hays_publication_events_text'];
        let metaTitle: string='';
        let metaLoc: string='';
        let metaDescription: string;
        let jobTitle: string = '';
        if (urlParams['searchPageTitle']) {
          metaTitle = urlParams['searchPageTitle'];
        }
        if (urlParams['location']) {
          metaLoc = urlParams['location'];
        }
        metaTitle = metaTitle ? decodeURI(metaTitle) : '';
        if (urlParams['searchPageDesc']) {
          metaDescription = urlParams['searchPageDesc'];
        }
        if(urlParams['q']){
          jobTitle = urlParams['q'];
        }
        if(environment[config.theme + this.siteservice.getSiteLocale()]['config']['dynamicPageTitleMetaTags']){
          this.updatePageTitle(metaTitle, jobTitle, metaLoc, res, environment[config.theme + this.siteservice.getSiteLocale()]['config']['country_name']);
        }
        metaDescription = metaDescription ? decodeURI(metaDescription) : '';
        let sharedImageUrl = '';
        let metaURL =  siteBaseURL + this.platformLocation.pathname;
        let orgNameList = ['/it/','/cognizant/', '/yourrailindustryjob/', '/geniuswithin/'];
        orgNameList.forEach(orgName => {
          if (this.platformLocation.pathname.indexOf(orgName) > -1) {
            metaURL = siteBaseURL + this.platformLocation.pathname.replace(orgName, '/');
            metaURL = decodeURIComponent(metaURL);
          }
        });
        if(siteBaseURL && siteBaseURL.indexOf('response') > -1) {
          sharedImageUrl = siteBaseURL +'/assets/images/Hays_R_RGB_OG_Sharing.jpg';
        }else {
          sharedImageUrl = siteBaseURL +'/assets/images/hays-og-optimize-image-new.png';
        }
        const tags = [
          {property: 'og:title', content: metaTitle},
          {name: 'description', content: metaDescription},
          {property: 'og:description', content: metaDescription},
          {name: 'author', content: author},
          {property: 'og:url', content: metaURL},
          {property: 'og:type', content: type},
          {property: 'og:image', content: sharedImageUrl}
        ];
        if(siteBaseURL && siteBaseURL.indexOf('response') > -1) {
          tags.push({property: 'og:image:width', content: '200'});
          tags.push({property: 'og:image:width', content: '200'});
        }else {
          tags.push({property: 'og:image:width', content: '382'});
          tags.push({property: 'og:image:width', content: '200'});
        }
        this.metaService.addTags(tags);
        if(environment[config.theme + this.siteservice.getSiteLocale()]['config']['canonicalSearchLinkCompleteURL']){
          metaURL = siteBaseURL + this.platformLocation.pathname + this.platformLocation.search;
          metaURL = decodeURIComponent(metaURL);
        }
        this.updateCanonicalUrl(metaURL);
      });
      this.SubscriptionList.push(setSearchPageMetaTagstranslationGetTranslationSubscription);
    }
  );
  this.SubscriptionList.push(setSearchPageMetaTagsAtivateRouteQueryParamsSubscription);
  }
  /**
   * Method to be called on server side and sets page title
   * @param metaTitle
   * @param jobTitle
   * @param location
   * @param countrySpecificLocale
   * @param countryName
   */
  private updatePageTitle(metaTitle, jobTitle, location, countrySpecificLocale, countryName){
    const splitwithoutspace=environment[config.theme + this.siteservice.getSiteLocale()]['config']['splitwithoutspace']?environment[config.theme + this.siteservice.getSiteLocale()]['config']['splitwithoutspace']:false;
    if(metaTitle){
      this.titleService.setTitle(metaTitle);
    }
    else{
      this.titleService.setTitle(DynamicPageTitlesUtility.getContentBasedOnSearch(location, jobTitle, countryName, countrySpecificLocale, 'title',splitwithoutspace));
    }
  }
  /**
   * Method to add apple itunes meta
   */
  private addAppleItunesMeta(){
    const addAppleItunesMetaJobSearchServiceSharedConfigListSubscription = this.jobSearchService.sharedConfigList.subscribe((domainConfig) => {
      if(domainConfig && domainConfig['appleItunesMeta']) {
        const element : HTMLMetaElement = this.renderer2.createElement('meta');
        element.setAttribute('name', 'apple-itunes-app');
        element.setAttribute('content', 'app-id=1577939990');
        this.renderer2.appendChild(this.document.head, element);
      }
    });
    this.SubscriptionList.push(addAppleItunesMetaJobSearchServiceSharedConfigListSubscription);
  }

  private convertTitleToSlug(title: string) {
    const str = title.toLowerCase().replace(/ /g, '-')
      //.replace(/[^\w-]+/g, '');
      .replace(/[`~!@#$%^&*()_|+\=?;:'",.<>\{\}\[\]\\\/]/gi, '');
    return str;
  }
  private convertLocationToSlug(location: string){
    location = location.replace(/\s*,\s*/g, ",");
    location = location.replace(/,(?=[^,]*$)/, ' ');
    return this.convertTitleToSlug(location);
  }
   /**
   * Feature# - 70560
   * Creates script element
   * Adds dynamic domain in the script source
   * Adds autoBlockAsset script in head after trustArc script
   * @param scriptDomain 
   */
   private addAutoBlockAssetScript(scriptDomain){
    let scriptElement = this.renderer2.createElement('script');
    scriptElement.src = `//consent.trustarc.com/autoblockasset/core.min.js?domain=${scriptDomain}`;
    this.renderer2.insertBefore(this.document.head, scriptElement, this.document.head.firstChild);
  }
  /**
   * Feature# - 70560
   * Creates script element
   * Adds dynamic domain in the script source
   * Adds autoBlockOptOut script in head after autoBlockAsset script
   * @param scriptDomain 
   */
  private addAutoBlockOptOutScript(scriptDomain){
    let scriptElement = this.renderer2.createElement('script');
    scriptElement.src = `//consent.trustarc.com/autoblockoptout?domain=${scriptDomain}`;
    this.renderer2.insertBefore(this.document.head, scriptElement, this.document.head.firstChild);
  }
  /**
   * Methd to update the azure link with GA values in case of
   * Login
   * Sign Out
   */
  private getAzureLoginURL(){
    if(this.gaService.gaCookieValue){
      this.updateLoginSignOutLink();
    }
    else{
      this.gaService.azureURLObservable.subscribe((response) => {
        if(this.gaService.gaCookieValue){
          this.updateLoginSignOutLink();
        }
        else if(!this.gaService.isIntervalExecuting){
          this.gaService.generateAzureURL();
        }
      });
    }
    if(!this.gaService.gaCookieValue && !this.gaService.isIntervalExecuting){
      this.gaService.generateAzureURL();
    }
  }
  /**
   * Method to update the links:
   * Login link when user is not signed in
   * Sign Out link for the logged in user
   */
  private updateLoginSignOutLink(){
    for(let element of this.rightTopHeader){
      if((element['resource_name'] === 'Login Page' || element['resource_name'] === 'Sign Out') && element['resource_url'].indexOf('_ga=') <= -1){
        element['resource_url'] = `${element['resource_url']}&_ga=${this.gaService.gaCookieValue}`
      }
    }
  }

  ngOnDestroy(): void {
    this.SubscriptionList.forEach(subscribe=>{
      if(subscribe){
        subscribe.unsubscribe();
    }
    });
  } 
}
