export class JobApplyRequest{
  constructor(public jobSource:string,
              public firstname:string,
              public lastname:string,
              public emailaddress:string,
              public privacyConsent:string,
              public jobidlist:string,
              public katkanafirstname:string,
              public katkanalastname:string,
              public cvData:CvData,
              public captchaResponse:string,
              public cvid:string,
              public cvname:string,
              public jobSeekerConsent:string,
              public employerResourceConsent:string,
              public eshotconsent:string,
              public marketingconsent:string,
              public smsconsent:string,
              public killerQuestion=[],
              public token:string,
              public candJobSource:string,
              public whatsupconsents:string,
              public expressConsent: string,
              public orgCode: string,
              public jobLead: string){
  }
}

export class CvData {
  constructor(public content: string,
              public contentType:string,
              public name:string,
              public size:string,
              public fileLink:string,
              public medium:string){
  }
}
