import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule, BrowserTransferStateModule, TransferState } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule } from '@angular/common';
import {HlaAppModule} from './hla-app/hla-app.module';
import {SiteLocaleComponent} from './global/components/shared/site-locale/site-locale.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {SiteService} from './global/services/site/site.service';
import {TokenStorage} from './global/services/tokenStorage/token-storage';
import {LogsService} from './global/services/logger/logs.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpServiceInterceptor} from './global/services/interceptor/httpInterceptor.interceptor';
import {HmacSecurity} from './global/services/HmacService/HmacSecurity';
import {HeaderComponent} from './global/components/shared/header/header.component';
import {environment} from '../environments/root/environment';
import {NgpSortModule} from 'ngp-sort-pipe';
import {AgmCoreModule} from '@agm/core';
import {SearchBoxComponent} from './global/components/shared/search-box/search-box.component';
import {CheckRedirectionRoutes} from './global/components/select-country/check-redirection-routes.component';
import {SharedModule} from './jobportal/shared/shared.module';
import {CookieService} from 'ngx-cookie-service';
import {JpCustomScripts} from './global/jp-custom-scripts/jp-script';
import {JpPartnerUploadCvComponent} from './global/anonymous/jp-partner-upload-cv/jp-partner-upload-cv.component';
import {SeekApplyComponent} from './global/anonymous/seek-apply/seek-apply.component';
import {JpViewEmailLinkAlertComponent} from './global/anonymous/jp-view-email-link-alert/jp-view-email-link-alert.component';
import {DmsSubmitAlertComponent} from './global/anonymous/dms-submit-alert/dms-submit-alert.component';
import {DmsVerifyAlertComponent} from './global/anonymous/dms-verify-alert/dms-verify-alert.component';
import {DMSThankYouPageComponent} from './global/anonymous/dms-thank-you-page/dms-thank-you-page.component';
import {FooterComponent} from './global/components/shared/footer/footer.component';
import {GoogleLocationFinderComponent} from './global/components/shared/google-location-finder/google-location-finder.component';
import {GlobalConsentComponent} from './global/anonymous/global-consent/global-consent.component';
import {JpUpdateConsentComponent} from "./global/anonymous/jp-update-consent/jp-update-consent.component";
import { Parser } from 'xml2js';
import { translateBrowserLoaderFactory } from './global/services/loaders/translate-browser.loader';
import { SearchModule } from './jobportal/shared/job/search/search.module';
import { ApplyModule } from './jobportal/shared/job/apply/apply.module';
import { LandingService } from './hla-app/secure/assessskill/services/landing.service';
import {HLAHeaderComponent} from './hla-app/secure/assessskill/header/header.component';
import {GlobalComponent} from './global/components/anonymous/global/global.component';
import {Page404Component} from './page404/page404.component';
import { ErpSharedModule } from './erp-integration/shared/erp-shared.module';
import { BrowserStateInterceptor } from './global/services/interceptor/browserstate.interceptor';
import { UnsubscribeEmailConfirmationModule } from './jobportal/notsecure/candidate/components/unsubscribe-email-confirmation.module';
import { OrganizationModule } from './organization/organization.module';
import { AppRouteInitService } from './global/services/AppRouteInitService/app-route-init.service';
import { NgHcaptchaModule } from 'ng-hcaptcha';
import { HcaptchaComponent } from './hcaptcha/hcaptcha.component';
import {JpAlertPopupComponent} from './global/components/shared/jp-alert-popup/jp-alert-popup.component';
import {MatDialogModule } from '@angular/material/dialog';
import { Go1CountryGuard } from './global/guards/go1CountryGuard';
export function initializeApp(appInitService: AppRouteInitService) {
  return (): Promise<any> => {
    return appInitService.init();
  }
}
@NgModule({
  declarations: [
    AppComponent,
    SiteLocaleComponent,
    HeaderComponent,
    GlobalConsentComponent,
    SearchBoxComponent,
    CheckRedirectionRoutes,
    JpCustomScripts,
    JpPartnerUploadCvComponent,
    JpUpdateConsentComponent,
    SeekApplyComponent,
    JpViewEmailLinkAlertComponent,
    DmsSubmitAlertComponent,
    DmsVerifyAlertComponent,
    DMSThankYouPageComponent,
    FooterComponent,
    GoogleLocationFinderComponent,
    HLAHeaderComponent,
    GlobalComponent,
    Page404Component,
    HcaptchaComponent,
    JpAlertPopupComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    RouterModule,
    BrowserModule,
    BrowserTransferStateModule,
    CommonModule,
    NgpSortModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MatDialogModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient, TransferState]
      }
    }),
    //NgIdleModule.forRoot(),
    BrowserAnimationsModule,
    AppRoutingModule,
    SearchModule,
    ApplyModule,
    ErpSharedModule,
    HlaAppModule,
    UnsubscribeEmailConfirmationModule,
    OrganizationModule,
    AgmCoreModule.forRoot({
      apiKey: environment.google_api_key,
      libraries: ['places']
    }),
    NgHcaptchaModule.forRoot({
      siteKey: 'ddfc0743-4d69-4d0f-b1ed-aeb96b7f51c7',
      languageCode: 'en' // optional, will default to browser language
    })
  ],
  providers: [
    LogsService,
    SiteService,
    TokenStorage,
    HmacSecurity,
    LandingService,
    CookieService,
    Go1CountryGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpServiceInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BrowserStateInterceptor,
      multi: true,
    },
    AppRouteInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [AppRouteInitService]
    },
    { provide: Parser, useValue: {} }],
  bootstrap: [AppComponent]
})
export class AppModule { }
