import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Route, Router, UrlSegment, UrlSegmentGroup } from '@angular/router';
import { JpRegisterNewComponent } from 'src/app/jobportal/shared/components/jp-register-new/jp-register-new.component';
import { SiteLocaleComponent } from '../../components/shared/site-locale/site-locale.component';
import { AuthGuard } from '../../guards/authGuard';
import { ROUTES_PATHS } from 'src/app/routes-paths';
import * as langMapping from '../../../../assets/language-mapping.json';
import { isPlatformServer, PlatformLocation } from '@angular/common';
import { JpSearchComponent } from 'src/app/jobportal/shared/job/search/components/jp-search/jp-search.component';
import { JpMyProfileComponent } from 'src/app/jobportal/secure/candidate/components/jp-my-profile/jp-my-profile.component';
import { JpDetailComponent } from 'src/app/jobportal/shared/job/jobdetail/components/jp-detail/jp-detail.component';
import { JpApplyComponent } from 'src/app/jobportal/shared/job/apply/components/jp-apply/jp-apply.component';
import { JpThankYouComponent } from 'src/app/jobportal/shared/job/apply/components/jp-thank-you/jp-thank-you.component';
import { JpMySavedJobComponent } from 'src/app/jobportal/shared/savedjob/components/jp-my-saved-job/jp-my-saved-job.component';
import { JpVerifyAlertComponent } from 'src/app/jobportal/shared/components/jp-verify-alert/jp-verify-alert.component';
import { JpAlertThankyouComponent } from 'src/app/jobportal/shared/components/jp-alert-thankyou/jp-alert-thankyou.component';
import { JpLoginComponent } from 'src/app/jobportal/notsecure/candidate/signin/components/jp-login/jp-login.component';
import { SuccessPagesComponent } from '../../components/shared/success-pages/success-pages.component';
import { JpAllOfficesComponent } from 'src/app/jobportal/office/components/jp-all-offices/jp-all-offices.component';
import { JpOfficeLocatorComponent } from 'src/app/jobportal/office/office-locator/components/jp-office-locator/jp-office-locator.component';
import { JpOfficeLocatorResultComponent } from 'src/app/jobportal/office/office-locator/components/jp-office-locator-result/jp-office-locator-result.component';
import { JpRecruitingNowComponent } from 'src/app/jobportal/notsecure/candidate/recruiting-now/components/jp-recruiting-now/jp-recruiting-now.component';
import { JpEmailConfirmationComponent } from 'src/app/jobportal/notsecure/candidate/gdpr/component/jp-email-confirmation/jp-email-confirmation.component';
import { JpMarketingPrefComponent } from 'src/app/jobportal/shared/gdpr/components/jp-marketing-pref/jp-marketing-pref.component';
import { JpMarketingPrefThankyouComponent } from 'src/app/jobportal/shared/gdpr/components/jp-marketing-pref-thankyou/jp-marketing-pref-thankyou.component';
import { JpMySearchesComponent } from 'src/app/jobportal/secure/candidate/components/jp-my-searches/jp-my-searches.component';
import { JpManageCvComponent } from 'src/app/jobportal/secure/candidate/components/jp-manage-cv/jp-manage-cv.component';
import { JpChangeEmailComponent } from 'src/app/jobportal/secure/candidate/components/jp-change-email/jp-change-email.component';
import { SiteService } from '../../services/site/site.service';
import { CookieService } from 'ngx-cookie-service';
import { JpChangePasswordComponent } from 'src/app/jobportal/secure/candidate/components/jp-change-password/jp-change-password.component';
import { JpForgotPasswordComponent } from 'src/app/jobportal/notsecure/candidate/signin/components/jp-forgot-password/jp-forgot-password.component';
import { JpUpdateWhatsappComponent } from 'src/app/jobportal/shared/whatsapp/component/jp-update-whatsapp/jp-update-whatsapp.component';
import { JpSaveWhatsappComponent } from 'src/app/jobportal/shared/whatsapp/component/jp-save-whatsapp/jp-save-whatsapp.component';
import { JpResetPasswordComponent } from 'src/app/jobportal/shared/components/jp-reset-password/jp-reset-password.component';
import { JpWhatsappComponent } from 'src/app/jobportal/shared/whatsapp/component/jp-whatsapp/jp-whatsapp.component';
import { Go1SigninRegisterComponent } from 'src/app/partner/go1-app/go1-notsecure/components/go1-signin/go1-signin-register/go1-signin-register.component';
import { ErrorPagesComponent } from '../../components/shared/error-pages/error-pages.component';
import { JpPartnerUploadCvComponent } from '../../anonymous/jp-partner-upload-cv/jp-partner-upload-cv.component';
import { JobDetailsGuard } from '../../guards/jobdetails.guard';
import { JpUnsubscribeEmailConfirmationComponent } from 'src/app/jobportal/notsecure/candidate/components/jp-unsubscribe-email-confirmation/jp-unsubscribe-email-confirmation.component';
import { JpUnsubscribeComponent } from 'src/app/jobportal/notsecure/candidate/alerts/jp-unsubscribe/jp-unsubscribe.component';
import { JpUpdatePrivacyAcceptanceComponent } from 'src/app/jobportal/notsecure/candidate/privacy-acceptance/components/jp-update-privacy-acceptance/jp-update-privacy-acceptance.component';
import { SeekApplyComponent } from '../../anonymous/seek-apply/seek-apply.component';
import { ErpMarketingPreferencesComponent } from 'src/app/erp-integration/shared/component/erp-marketing-preferences/erp-marketing-preferences.component';
import { ErpManageJobAlertComponent } from 'src/app/erp-integration/shared/component/erp-manage-job-alert/erp-manage-job-alert.component';
import { Go1SpecCvComponent } from 'src/app/partner/go1-app/go1-notsecure/components/go1-signin/go1-spec-cv/go1-spec-cv.component';
import { Go1WidgetComponent } from 'src/app/partner/go1-app/go1-notsecure/components/go1-widget/go1-widget.component';
import { SecureGatewayComponent } from 'src/app/erp-integration/erp-notsecure/secure-gateway/secure-gateway.component';
import { ThPrivacyPolicyUpdateComponent } from 'src/app/jobportal/notsecure/candidate/privacy-acceptance/components/jp-update-privacy-acceptance/th-privacy-policy-update/th-privacy-policy-update.component';
import { JpViewEmailLinkAlertComponent  } from '../../anonymous/jp-view-email-link-alert/jp-view-email-link-alert.component';
import { HcaptchaComponent } from 'src/app/hcaptcha/hcaptcha.component';
import {
  JpMarketingPrefContactComponent
} from "../../../jobportal/shared/gdpr/components/jp-marketing-pref-contact/jp-marketing-pref-contact.component";
import { Go1CountryGuard } from '../../guards/go1CountryGuard';
export function customUrlMatcher(searchTitlePath : string, searchLocPath : string, searchJobPath: string , orgName:string) {
  return (
    url: UrlSegment[]
  ) => {
    const completeURLs = url.map( u => u.path);
    const completeUrl = completeURLs.join('/');
    return url.length > 0 && (completeUrl.startsWith(searchTitlePath) || completeUrl.startsWith(orgName+'/'+searchTitlePath) || completeUrl.startsWith(searchJobPath+'/'+searchLocPath) || completeUrl.startsWith(orgName+'/'+searchJobPath+'/'+searchLocPath))
      ? ({consumed: url,
        posParams: {
          orgName: new UrlSegment(orgName, {})
        }})
      : null;
  }

}
@Injectable()
export class AppRouteInitService {
  constructor(private Router: Router, @Inject(PLATFORM_ID) private platformId: object, private location: PlatformLocation, private _siteService: SiteService, private cookieService: CookieService) { }
  init() {
    return new Promise<void>((resolve, reject) => {
      let orgNameList = ['/it/', '/cognizant/', '/yourrailindustryjob/', '/careers'];

      const routes = this.Router.config;
      let siteLocale = '';
      let lang = '';
      let domain = '';
      let isOrgSite = false;
      let langQuery = '';
      let orgName = '';
      const urlParams = new URL(this.location.href);

      if (urlParams.pathname) {
        orgNameList.filter(name => {
          if (urlParams.pathname.includes(name)) {
            if(urlParams.pathname[1] == name) {
              isOrgSite = true;
            }
            orgName = name.split('/')[1];
          }
        });
        const paths = urlParams.pathname.split('/');
        langQuery = isOrgSite ? paths[1] : paths[1];
      }

      let hostName = '';
      if (isPlatformServer(this.platformId)) {
        hostName = this.location.hostname;
      } else {
        hostName = location.hostname;
      }
      if (hostName.indexOf('moatvus') > -1 || hostName.indexOf('vereduscorp') > -1) {
        hostName = 'veredus.hays.vus';
      }
      let len = hostName.length;
      let n = hostName.indexOf('.');
      if (hostName.indexOf('.') > -1) {
        hostName = hostName.substring(n, len);
      }
      if (hostName === 'localhost') {
        hostName = '.hays.com.sg'; //'.hays.co.uk' '.hays.ie' '//.hays.fr'
      }
      let languageMapping: any;
      let domainDetails: any;
      languageMapping = langMapping;
      languageMapping = languageMapping.default;
      if (hostName && languageMapping) {
        domainDetails = languageMapping[hostName];
      }
      domain = domainDetails.domain;
      if (langQuery && domainDetails.language[langQuery]) {
        lang = domainDetails.language[langQuery];
      } else {
        lang = domainDetails.defaultLang
      }
      siteLocale = lang + '_' + domain;
      //console.log(siteLocale);
      // console.log(ROUTES_PATHS[siteLocale]);
      const routePath = ROUTES_PATHS[siteLocale];
      if(routePath){
        // if (routePath && (decodeURIComponent(urlParams.pathname).startsWith('/'+orgName + routePath.locale_jobs_link.replace(':lang', langQuery)) || decodeURIComponent(urlParams.pathname).startsWith('/' + orgName + routePath.locale_account_link.replace(':lang', langQuery)) || decodeURIComponent(urlParams.pathname).startsWith('/' + orgName + routePath.locale_portal_link.replace(':lang', langQuery)))) {
        if(urlParams.pathname.indexOf(routePath.gdrive_link)===-1 && urlParams.pathname.indexOf(routePath.dropbox_link)===-1){
          this._siteService.setSite('/' + domain + '/' + lang + '/');
          //let cookieDomainName = environment[config.theme + this._siteService.getSiteLocale()][apiEndPoints.cookieDomain];
          this.cookieService.set('multi_country', lang + '-' + domain);
        }

        // this.cookieService.set('multi_country', lang + '-' + domain, 0, '/', cookieDomainName);
        //console.log('95', this._siteService.getSiteLocale());
        if(isOrgSite){
          langQuery = langQuery+'/'+orgName;
        }
        // const searchTitlePath = routePath.search_link_title.replace(':lang',langQuery);
        // const searchLocPath =  routePath.search_link_location;
        // const searchJobPath = routePath.locale_jobs_link.replace(':lang',langQuery);
        const route = [
          {
            path: isOrgSite ? routePath.locale_org_job_search_link : routePath.locale_job_search_link,
            component: SiteLocaleComponent,
            canActivate: [AuthGuard],
            children: [
              { path: '', component: JpSearchComponent },
              { path: routePath.search_link, component: JpSearchComponent },
            ]
          },
          {
            path: isOrgSite ? routePath.locale_org_job_detail_link : routePath.locale_job_detail_link,
            component: SiteLocaleComponent,
            canActivate: [AuthGuard],
            children: [
              { path: routePath.job_detail_link, component: JpDetailComponent, canActivate:[JobDetailsGuard] },
              { path: routePath.job_apply_link, component: JpApplyComponent },
              { path: routePath.job_apply_link+'/', component: JpApplyComponent },
              { path: routePath.job_apply_confirmation_link, component: JpThankYouComponent },
            ]
          },
          {
            path: isOrgSite ? routePath.locale_org_account_link : routePath.locale_account_link,
            component: SiteLocaleComponent,
            canActivate: [AuthGuard],
            children: [
              { path: routePath.spec_cv_link, component: JpRegisterNewComponent },
              { path: routePath.register_link, component: JpRegisterNewComponent },
              { path: routePath.success_link, component: SuccessPagesComponent },
              { path: routePath.login_link, component: JpLoginComponent },
              { path: 'logout', component: JpLoginComponent },
              { path: routePath.forgot_password_link, component: JpForgotPasswordComponent },
              { path: routePath.saved_jobs_link, component: JpMySavedJobComponent },
              { path: routePath.unsubscribe_link, component: JpUnsubscribeComponent},
              { path: routePath.unsubscribe_email_link, component: JpUnsubscribeEmailConfirmationComponent},
              { path: routePath.update_preferences_email_link, component: JpEmailConfirmationComponent },
              { path: routePath.sent_preferences_email_link, component: SuccessPagesComponent },
              { path: routePath.update_your_details_link, component: JpRegisterNewComponent },
              { path: routePath.update_your_privacy_link, component: JpUpdatePrivacyAcceptanceComponent },
              { path: routePath.whatsapp_alert_link, component: JpWhatsappComponent},
              { path: routePath.reset_link, component: JpResetPasswordComponent},
              { path: routePath.error_link, component: ErrorPagesComponent },
              { path: routePath.gdpr_marketing_pref_link, component: JpMarketingPrefComponent },
              { path: routePath.gdpr_marketing_pref_thankyou_link, component: JpMarketingPrefThankyouComponent },
              { path: routePath.verify_alert_link, component: JpVerifyAlertComponent },
              { path: routePath.verify_alert_link+'/', component: JpVerifyAlertComponent },
              { path: routePath.job_alert_success_link, component: JpAlertThankyouComponent },
              { path: routePath.delete_alert_link, component: JpVerifyAlertComponent },
              { path: routePath.gdpr_marketing_pref_contact_link, component: JpMarketingPrefContactComponent },
              { path: 'erp/marketing-preferences', component: ErpMarketingPreferencesComponent},
              { path: 'erp/marketing-preferences-confirmed', component: ErpMarketingPreferencesComponent},
              { path: 'erp/delete-alert', component: JpVerifyAlertComponent},
              { path: 'erp/delete-alert/success', component:JpAlertThankyouComponent},
              { path: 'erp/job-alerts', component: ErpManageJobAlertComponent},
              { path: 'online-learning', component:Go1SpecCvComponent,
                canActivate: [Go1CountryGuard]
              },
              { path: 'online-learning/training', component:Go1WidgetComponent},
              { path: 'online-learning/training/login', component:Go1SigninRegisterComponent},
              { path: 'erp/alert-secure-gateway', component: SecureGatewayComponent},
              { path: 'erp/secure-gateway', component: SecureGatewayComponent},
              { path: routePath.unsubscribe_email_confirmed_link, component: SuccessPagesComponent },
              { path: 'UpdateThPrivacyPolicy', component: ThPrivacyPolicyUpdateComponent},
              { path: routePath.view_email_link, component: JpViewEmailLinkAlertComponent },
              { path : 'test-captcha', component: HcaptchaComponent }
            ]
          },
          {
            path: isOrgSite ? routePath.locale_org_contact_link : routePath.locale_contact_link,
            component: SiteLocaleComponent,
            canActivate: [AuthGuard],
            children: [
              { path: routePath.all_office_link, component: JpAllOfficesComponent },
              { path: routePath.office_locator_link, component: JpOfficeLocatorComponent },
              { path: routePath.office_locator_result_link, component: JpOfficeLocatorResultComponent }
            ]
          },
          {
            path: isOrgSite ? routePath.locale_org_recruitment_link : routePath.locale_recruitment_link,
            component: SiteLocaleComponent,
            canActivate: [AuthGuard],
            children: [
              { path: routePath.recruiting_now_link, component: JpRecruitingNowComponent },
            ]
          },
          {
            path: isOrgSite ? routePath.locale_org_my_account_link : routePath.locale_my_account_link,
            component: SiteLocaleComponent,
            canActivate: [AuthGuard],
            children: [
              { path: routePath.unsubscribe_email_confirmed_link, component: SuccessPagesComponent },
              { path: routePath.gdpr_marketing_pref_link, component: JpMarketingPrefComponent },
              { path: routePath.gdpr_marketing_pref_thankyou_link, component: JpMarketingPrefThankyouComponent },
              { path: routePath.my_profile_link, component: JpMyProfileComponent },
              { path: routePath.saved_searches_link, component: JpMySearchesComponent },
              { path: routePath.manage_cv_link, component: JpManageCvComponent },
              { path: routePath.change_email_link, component: JpChangeEmailComponent },
              { path: routePath.change_password_link, component: JpChangePasswordComponent },
              { path: routePath.update_whatsapp_link, component: JpUpdateWhatsappComponent },
              { path: routePath.save_whatsapp_link, component: JpSaveWhatsappComponent },
              { path: 'erp/job-alerts', component: ErpManageJobAlertComponent},
              { path: 'erp/marketing-preferences', component: ErpMarketingPreferencesComponent},
              { path: 'erp/marketing-preferences-confirmed', component: ErpMarketingPreferencesComponent},

            ]
          },
          {
            path: routePath.dropbox_link,
            component: JpPartnerUploadCvComponent
          },
          {
            path: routePath.gdrive_link,
            component: JpPartnerUploadCvComponent
          },
          {
            path: 'seekApply',
            component: SeekApplyComponent
          },
          {
            path:"**",
            redirectTo:'/'+routePath.locale_account_link.replace(':lang',lang)+'/'+routePath.error_link+'?notFound=404'
          }
        ];
        const r: Route[] = [
          ...route,
          ...routes

        ]
        this.Router.resetConfig(r);
      }
      resolve();
    });
  }
}
