<section class="marketing-preferences">
  <h2 [translate]="'marketing_preference_contact_heading_text'"></h2>
  <p [translate]="'marketing_preferences_contact_header'"></p>
  <!--<app-custom-loader ></app-custom-loader>-->
  <div class="row">
    <div class="col-sm-12">
      <h3 [translate]="'notification_email_contact_text'"></h3>
    </div>
    <div class="col-sm-12">
      <div class="border">
        <div class="row">
          <div class="col-sm-12 col-md-6" >
            <div class="panel-box">
              <p [translate]="'eshot_contact_text'"></p>
              <p [translate]="'gdpr_providing_this_consent_contact_text'"></p>
              <p [translate]="'gdpr_recieve_email_contact_text'"></p>
              <div class="switch-field">
                <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" >
                  <div class="toggle-btn">
                    <button class="btn" [ngClass]="marketingPrefContactPayloadData.eshot=='Y' ? 'active' : ''" (click)="setService($event,'email')" [translate]="'yes_contact_text'" >
                    </button>
                    <button class="btn" [ngClass]="marketingPrefContactPayloadData.eshot=='N' ? 'active' : ''" (click)="setService($event,'email')"  [translate]="'no_contact_text'">
                    </button>
                  </div>
                </div>
              </div><br>
              <p class="email-second-head" [translate]="'gdpr_bd_call_contact_text'"></p>
              <p class="email-second-head" [translate]="'providing_consent_via_mobile_contact_text'"></p>
              <p class="email-second-head" [translate]="'you_by_phone_contact_text'"></p>

              <div class="switch-field">
                <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" >
                  <div class="toggle-btn">
                    <button class="btn" [ngClass]="marketingPrefContactPayloadData.businessDev=='Y' ? 'active' : ''" (click)="setService($event,'bdcall')"  [translate]="'yes_contact_text'" >
                    </button>
                    <button class="btn" [ngClass]="marketingPrefContactPayloadData.businessDev=='N' ? 'active' : ''" (click)="setService($event,'bdcall')"  [translate]="'no_contact_text'">
                    </button>
                  </div>
                </div>
              </div><br>
            </div>
          </div>
          <div class="col-sm-12 col-md-6" >

            <!-- <h3 [translate]="'gdpr_mobile_heading_text'"></h3>-->
            <!--<p class="heading-text"[translate]="'gdpr_mobile_sub_heading_text'"></p>-->
            <div class="panel-box">
              <div class="option-box">
                <p class="sms-text"[translate]="'survey_contact_text'"></p>
                <div class="toggle">
                  <p [translate]="'providing_consent_evaluation_servey_contact_text'"></p>
                  <p class="email-second-head" [translate]="'you_by_phone_survey_contact_text'"></p>
                  <div class="switch-field">
                    <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic"  >
                      <div class="toggle-btn">
                        <button class="btn" [ngClass]="marketingPrefContactPayloadData.survey=='Y' ? 'active' : ''" (click)="setService($event,'survey')"  [translate]="'yes_contact_text'" >
                        </button>
                        <button class="btn" [ngClass]="marketingPrefContactPayloadData.survey=='N' ? 'active' : ''" (click)="setService($event,'survey')"  [translate]="'no_contact_text'">
                        </button>
                      </div>
                    </div>
                  </div>
                </div><br>
                <div class="toggle">
                  <p [translate]="'spec_cv_contact_text'"></p>
                  <p [translate]="'gdpr_providing_recruitment_service_contact_text'"></p>
                  <p [translate]="'gdpr_recieve_email_contact_text1'"></p>
                  <div class="switch-field">
                    <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" >
                      <div class="toggle-btn">
                        <button class="btn" [ngClass]="marketingPrefContactPayloadData.specCv=='Y' ? 'active' : ''" (click)="setService($event,'speccv')"  [translate]="'yes_contact_text'" >
                        </button>
                        <button class="btn" [ngClass]="marketingPrefContactPayloadData.specCv=='N' ? 'active' : ''" (click)="setService($event,'speccv')" [translate]="'no_contact_text'">
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="col-sm-12">
      <div class="">
        <div class="row">
          <div class="col-sm-12 col-md-6 ml-auto" >
            <div class="panel-box mt-3 border save-info">
              <span class="save-icon"  [translate]="'save_your_changes_contact_text'"></span>
              <span class="save-btn text-right"><button type="submit" [ngClass]="updateClicked?'btn btn-default btn-block btn-loading':'btn btn-default btn-block'" id="saveButton" [disabled]="isButtonDisable" (click)="updateMarketingPrefContact()" [translate]="'save_contact_text'"></button>
            </span>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <app-jp-cookies-settings ></app-jp-cookies-settings>
    </div>
  </div>



</section>
<jw-modal *ngIf ="isOpenModal" id="market_pref_contact_success_error_msg" class="modal-popup">
  <div class="modal-header border-0">
    <button type="button" class="close" (click)="closeModal('market_pref_contact_success_error_msg')" data-dismiss="modal">&times;</button>
  </div>
  <div class="modal-body">
    <h2 class="border-0 mb-1 d-flex py-1" *ngIf="isSuccessMsg">
      <img class="mr-4 jb-market-img" src="../assets/images/success_icon.png" />
      <div>
        <span class="text-dark" [translate]="'marketing_pref_contact_success_text'"></span>
        <p class="text-dark mt-1" *ngIf="isSuccessMsg" [translate]="'marketing_pref_contact_success_msg_text'"></p>
      </div>
    </h2>
    <h2 class="border-0 mb-1 d-flex py-1" *ngIf="isErrorMsg">
      <img class="mr-4 jb-market-img" src="../assets/images/error_icon.png" />
      <div>
        <span  class="text-dark" [translate]="'marketing_pref_contact_error_text'"></span>
        <p class="text-dark mt-1" *ngIf="isErrorMsg" [translate]="'marketing_pref_contact_error_msg_text'"></p>
      </div>
    </h2>

  </div>

</jw-modal>
